export const TEST = 'Hello';

export const deploymentName = 'pephealthappdev';
export const envName = 'pephealth-dev';

export const domain = 'dev.au.pephealthapp.com';

// General App config
export const appToken = '06304a';
export const appId = 2;
export const ubiquityCompanyId = 3;
// "dev-forpatientapp-staff" Cognito User Pool
export const userPoolId = 'ap-southeast-2_ko6I4y4PX';
export const userPoolAppClient = '27306qg49mht5p18tnkgur85tv';

// IDM
export const idmOrganisation = 3;
export const organisationSlug = 'liquid-state';
export const idmApiRoot = `https://idm.${domain}/`;
export const organisationUrl = `${idmApiRoot}api/v1/organisations/`;
export const idmLogin = `https://idm.${domain}/api/login/`;
export const idmLogout = `https://idm.${domain}/logout/`;
// export const idmTokens = `https://idm.${domain}/api/tokens/`;

export const pepHealthApiRoot = `https://pephealth.${domain}/`;

// CMS apps
export const carbonURL = `https://carbon.${domain}`;
export const ubiquityURL = `https://ubiquity.${domain}`;

// UIS
export const uisApiRoot = `https://uis.${domain}/`;

// PIP
export const pipApiRoot = `https://pip.${domain}`;
export const pipAppUrl = `https://pip.${domain}/api/admin/v1/apps/`;

export const ubiquityApiRoot = `https://ubiquity.${domain}/`;
export const pathwaysApiRoot = `https://pathways.${domain}/v1/apps/{{app_ubiquity_token}}/`;

export const messagingApiRoot = `https://ubiquity.${domain}/api/core/v1/apps/`;

export const organisationDirectoryApiRoot = `https://directory.${domain}/api/v1/`;

export const videoCreationEndpoint = `https://2mh4b041g0.execute-api.ap-southeast-2.amazonaws.com/dev/create`;
export const videoExportStatusEndpoint = `https://2mh4b041g0.execute-api.ap-southeast-2.amazonaws.com/dev/export_status`;
export const videoCEDetails = {
  ce_space_uuid: 'd7b48e6d-694c-4f14-86dc-33c29014deb9',
  ce_category_slug: 'dev-videos',
  template_name: 'pep-health-video-en',
};
export const videoUbiquityExportDetails = {
  theme_slug: 'default-style',
  ubiquity_app_id: appId,
  ubiquity_company_id: ubiquityCompanyId,
  ubiquity_category_ids: ['2'],
};

export const mixpanelToken = '49be1ec2d8b0c80de23ce0bb07f9f70f';
export const mixpanelOptions = {
  // disable_persistence: true
  // api_host: "https://api-eu.mixpanel.com",
};
export const analyticsHiddenSuperProperties = ['firstName', 'lastName', 'name'];

export const metricsUrlRoot = '';

export const documentTemplateUrl =
  'https://pephealthapp-assets.s3.ap-southeast-2.amazonaws.com/Document+Templates.zip';

export const FEATURES = {
  help: {
    url: 'https://liquidstate.atlassian.net/wiki/spaces/PDKB/overview',
  },
  // remove the display and tracking of Terms & Conditions and Privacy Policy (instead have short text for TCs and link to Privacy Policy doc)
  noTermsTrackingForDashboardUsers: false,
  appUsersControlledDocsForAllOrgs: true,
  evereg: false,
};

export const envHasDataLake = true;
export const dataLakeBaseURL =
  'https://vox5rptx5e.execute-api.ap-southeast-2.amazonaws.com/dev/admin_user_file_url';
export const dataLakeFormDataDownloadURL = `${dataLakeBaseURL}?application=form_submissions_csv&path=by_form/FORM_ID/all.csv`;
export const dataLakeFormDataDownloadForAppUserURL = `${dataLakeBaseURL}?application=form_submissions_csv&path=by_app_user/APP_USER_ID/FORM_ID.csv`;

export const restrictSharedSnapshotsCreation = false;

export const showAppUserHealthDataScreen = true;

export const kemtaiPatientURL = 'https://care-manage.kemtai.com/users/KEMTAI_ID';
