import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { string } from 'prop-types';
import { Card, Checkbox, Collapse, Button, Input, Icon, Form, Alert, Radio } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import OnboardingContainer from '@components/OnboardingContainer';
import ControlledDocumentModal from '@components/ControlledDocumentModal';
import { isSubmitting, hasError as hasErrorSelector } from '@redux/registration/reducer';
import { registrationSubmitted } from '@redux/registration/actions';
import { selectTermsAndConditions, selectPrivacyPolicies } from '@redux/termsOfUse/reducers';
import { validatePasswordComplexity } from '@utils';
import { useFeature } from '@hooks';
import { antFormType } from '../../../propTypes';
import './style.less';

function RegistrationDetails({
  form: {
    getFieldDecorator,
    validateFieldsAndScroll,
    getFieldValue,
    getFieldsValue,
    validateFields,
  },
}) {
  const [confirmDirty, updateDirty] = useState(false);
  const [controlledDocumentModalData, setControlledDocumentModalData] = useState(false);
  const [hidden, toggleHidden] = useState(true);
  const { noTermsTrackingForDashboardUsers } = useFeature('noTermsTrackingForDashboardUsers');
  const email = useSelector(state => state.registration.credentails.email);
  const loading = useSelector(isSubmitting);
  const termsAndConditions = useSelector(selectTermsAndConditions);
  const privacyPolicies = useSelector(selectPrivacyPolicies);
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    event => {
      event.preventDefault();
      validateFieldsAndScroll((err, values) => {
        if (!err) {
          dispatch(registrationSubmitted(values));
        }
      });
    },
    [validateFieldsAndScroll, dispatch],
  );

  const toggleVisibility = () => {
    toggleHidden(!hidden);
  };

  const validateWithConfirmPassword = (rule, value, callback) => {
    if (value && confirmDirty) {
      validateFields(['confirmPassword'], { force: true });
    }
    callback();
  };

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== getFieldValue('password')) {
      callback(callback(t('registration:Details.confirmPassword.validation.inconsistentError')));
    } else {
      callback();
    }
  };

  const handleConfirmBlur = e => {
    const { value } = e.target;
    updateDirty(confirmDirty || !!value);
  };

  const formValues = getFieldsValue();
  const disabledSubmit = !Object.keys(formValues).every(key => formValues[key]);

  const hasError = useSelector(hasErrorSelector);

  const { t } = useTranslation();

  return (
    <>
      <OnboardingContainer>
        <Form className="registration-form" layout="vertical" onSubmit={handleSubmit}>
          <Card
            title={<h1>{t('registration:Details.title')}</h1>}
            actions={[
              <Button
                type="primary"
                onClick={handleSubmit}
                disabled={disabledSubmit}
                loading={loading}
                htmlType="submit"
              >
                {t('common:buttons.submit')}
              </Button>,
            ]}
            className="login-card registration-details"
          >
            <p>{t('registration:Details.description')}</p>
            <h3>{t('registration:Details.subtitle')}</h3>

            <Form.Item label={t('registration:Details.username.label')}>
              {getFieldDecorator('email', { initialValue: email, rules: [{ required: true }] })(
                <Input disabled />,
              )}
            </Form.Item>
            <small>{t('registration:Details.username.help')}</small>
            <Form.Item label={t('registration:Details.newPassword.label')} hasFeedback>
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    message: t('registration:Details.newPassword.validation.emptyError'),
                  },
                  { validator: validatePasswordComplexity },
                  { validator: validateWithConfirmPassword },
                ],
              })(
                <Input
                  placeholder={t('registration:Details.newPassword.placeholder')}
                  prefix={<Icon type="lock" />}
                  suffix={
                    <Icon type={hidden ? 'eye-invisible' : 'eye'} onClick={toggleVisibility} />
                  }
                  type={hidden ? 'password' : 'text'}
                />,
              )}
            </Form.Item>
            <Form.Item hasFeedback>
              {getFieldDecorator('confirmPassword', {
                rules: [
                  {
                    required: true,
                    message: t('registration:Details.confirmPassword.validation.emptyError'),
                  },
                  { validator: compareToFirstPassword },
                ],
              })(
                <Input
                  placeholder={t('registration:Details.confirmPassword.placeholder')}
                  prefix={<Icon type="lock" />}
                  suffix={
                    <Icon type={hidden ? 'eye-invisible' : 'eye'} onClick={toggleVisibility} />
                  }
                  type={hidden ? 'password' : 'text'}
                  onBlur={handleConfirmBlur}
                />,
              )}
            </Form.Item>
            <small>{t('registration:Details.confirmPassword.help')}</small>
            <Form.Item label={t('registration:Details.mobile.label')} hasFeedback>
              {getFieldDecorator('phoneNumber', {
                rules: [
                  {
                    required: true,
                    message: t('registration:Details.mobile.validation.emptyError'),
                  },
                  {
                    pattern: /^\+[\d() ]+$/,
                    message: t('registration:Details.mobile.validation.invalidError'),
                  },
                ],
              })(
                <Input
                  type="text"
                  inputMode="numeric"
                  prefix={<Icon type="mobile" />}
                  placeholder={t('registration:Details.mobile.label')}
                  onChange={e => {
                    const { value } = e.target;
                    if (value.startsWith('04')) {
                      e.target.value = `+61${value.slice(1)}`;
                    }
                  }}
                />,
              )}
            </Form.Item>
            <small>{t('registration:Details.mobile.help')}</small>
            <Form.Item label={t('registration:Details.mfa.label')}>
              {getFieldDecorator('mfa', {
                rules: [
                  {
                    required: true,
                  },
                ],
                initialValue: 'SMS_MFA',
              })(
                <Radio.Group>
                  <Radio value="SMS_MFA">{t('registration:Details.mfa.sms')}</Radio>
                  <Radio value="EMAIL_OTP">{t('registration:Details.mfa.email')}</Radio>
                </Radio.Group>,
              )}
            </Form.Item>
            <small>{t('registration:Details.mfa.help')}</small>

            {noTermsTrackingForDashboardUsers ? (
              <>
                <h3 className="registration-agreements-title">
                  {t('registration:Details.agreementsNoTracking.title')}
                </h3>
                <p>
                  <strong>{t('registration:Details.agreementsNoTracking.termsTitle')}</strong>{' '}
                  &mdash; {t('registration:Details.agreementsNoTracking.terms')}
                </p>
                {privacyPolicies.length ? (
                  <p>
                    <Trans i18nKey={'registration:Details.agreementsNoTracking.privacy'}>
                      I have read the
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={privacyPolicies[0].latest_version.version_content.data.url}
                      >
                        Privacy Policy
                      </a>
                    </Trans>
                  </p>
                ) : null}
              </>
            ) : (
              <>
                <h3 className="registration-agreements-title">
                  {t('registration:Details.agreements.title')}
                </h3>
                <Collapse bordered={false}>
                  <Collapse.Panel
                    header={<h3>{t('registration:Details.agreements.privacy.title')}</h3>}
                  >
                    {privacyPolicies.map(privacyPolicy => (
                      <Button
                        block
                        key={privacyPolicy.uuid}
                        onClick={() =>
                          setControlledDocumentModalData({
                            title: privacyPolicy.latest_version.version_content.display_name,
                            url: privacyPolicy.latest_version.version_content.data.url,
                          })
                        }
                      >
                        {t('registration:Details.agreements.read', {
                          documentName: privacyPolicy.latest_version.version_content.display_name,
                        })}
                      </Button>
                    ))}
                  </Collapse.Panel>
                  <Collapse.Panel
                    header={<h3>{t('registration:Details.agreements.terms.title')}</h3>}
                  >
                    {termsAndConditions.map(terms => (
                      <Button
                        block
                        key={terms.uuid}
                        onClick={() =>
                          setControlledDocumentModalData({
                            title: terms.latest_version.version_content.display_name,
                            url: terms.latest_version.version_content.data.url,
                          })
                        }
                      >
                        {t('registration:Details.agreements.read', {
                          documentName: terms.latest_version.version_content.display_name,
                        })}
                      </Button>
                    ))}
                  </Collapse.Panel>
                </Collapse>
                <h4>{t('registration:Details.agreements.subtitle')}</h4>
                <Form.Item>
                  {getFieldDecorator('privacyPolicyAgreement', {
                    rules: [{ required: true }],
                    valuePropName: 'checked',
                  })(<Checkbox>{t('registration:Details.agreements.privacy.label')}</Checkbox>)}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('termsAndConditionsAgreement', {
                    rules: [{ required: true }],
                    valuePropName: 'checked',
                  })(<Checkbox>{t('registration:Details.agreements.terms.label')}</Checkbox>)}
                </Form.Item>
              </>
            )}

            {hasError && (
              <Alert
                message={
                  typeof hasError === 'string' ? hasError : t('registration:Details.errors.generic')
                }
                type="error"
                showIcon
              />
            )}
          </Card>
        </Form>
        {controlledDocumentModalData ? (
          <ControlledDocumentModal
            title={controlledDocumentModalData.title}
            url={controlledDocumentModalData.url}
            visible={true}
            onClose={() => setControlledDocumentModalData(false)}
          />
        ) : null}
      </OnboardingContainer>
    </>
  );
}

RegistrationDetails.propTypes = {
  email: string,
  form: antFormType.isRequired,
};

export default Form.create()(RegistrationDetails);
